import {FirebaseUserRescueOrg, GlobalUserRoles, UserState, UserStore} from './user.store';
import { inject, Injectable } from '@angular/core';
import {
  Auth,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  updateEmail,
  verifyBeforeUpdateEmail,
  reauthenticateWithCredential,
  UserCredential
} from '@angular/fire/auth';
import {getDoc, doc, Firestore, updateDoc} from '@angular/fire/firestore';
import {Router} from '@angular/router';
import {UserQuery} from './user.query';
import {LocalStorage} from '../browser/browser.service';
import {fromPromise} from "rxjs/internal/observable/innerFrom";
import {RescueOrgInUser} from "../rescue-org/rescue-org.store";
import {userCollectionKey} from "../../globalVariables";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

export interface UserCredentials {
  email: string;
  password: string;
}

const GLOBAL_ROLES = ['superAdmin']

@Injectable({providedIn: 'root'})
export class UserService {
  private firestore: Firestore = inject(Firestore);
  private auth: Auth = inject(Auth);
  private router = inject(Router);
  private userStore = inject(UserStore);
  private userQuery = inject(UserQuery);
  private fb = inject(FormBuilder);

  userDetailsForm: FormGroup = this.fb.group({
    fullName: [''],
    phone: [''],
    email: ['']
  });

  userPasswordForm: FormGroup = this.fb.group({
    old: ['', [Validators.required]],
    new: ['', [Validators.required]],
    confirm: ['', [Validators.required]],
  });

  authCredentials!: UserCredential;

  setSelectedRescueOrgId(shouldBeSelectedOrgId: string) {
    this.userStore.update({ selectedOrgId: shouldBeSelectedOrgId });
  }

  login(credentials: UserCredentials) {
    signInWithEmailAndPassword(this.auth, credentials.email, credentials.password)
      .then((userCredential) => {
        this.authCredentials = userCredential;
        this.updateAuthUser(userCredential.user.uid);
      })
  }

  updateAuthUser(uid: string) {
    const userDoc = getDoc(doc(this.firestore, userCollectionKey, uid));
    userDoc.then((userData) => {
      const data = userData.data() as UserState;
      const orgIds = Object.keys(data.connectedOrgs);
      const orgDetails = Object.values(data.connectedOrgs);
      const orgArray = orgIds.map((id, index) => {
        return {
          id,
          favourite: orgDetails[index].favourite,
          name: orgDetails[index].name,
          role: orgDetails[index].role
        }
      });
      orgArray.sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        return 0;
      })
      const updatedData: UserState = {
        ...data,
        id: uid,
        connectedOrgs: [...orgArray]
      }
      this.userStore.update(updatedData);
      if (window.localStorage.getItem(LocalStorage.lastVisitedPage)) this.router.navigate([window.localStorage.getItem(LocalStorage.lastVisitedPage)]);
      else this.router.navigate(['iranyitopult'])
    })
  }

  updateUserDetails(uid: string) {
    return fromPromise(new Promise(async (resolve, reject) => {
      const userDocRef = doc(this.firestore, userCollectionKey, uid);
      const updatedUserData = this.userDetailsForm.value;

      await updateDoc(userDocRef, {
        ...updatedUserData
      });
      this.userStore.update(updatedUserData);
      resolve(`${updatedUserData?.fullName}`);
    }));
  }

  updateFavourite(orgId: string) {
    return fromPromise(new Promise(async (resolve, reject) => {
      const userDocRef = doc(this.firestore, userCollectionKey, this.userQuery.uid);
      const userData = {...this.userQuery.firebaseUser};

      const orgToUpdate: RescueOrgInUser | undefined = userData.connectedOrgs.find((org) => org.id === orgId);
      const currentFav = this.userQuery.favouriteRescueOrg;

      if (orgToUpdate && currentFav) {
        if (orgToUpdate === currentFav) orgToUpdate.favourite = false;
        if (orgToUpdate !== currentFav) {
          currentFav.favourite = false;
          orgToUpdate.favourite = true;
        }
      }

      if (orgToUpdate && !currentFav) {
        orgToUpdate.favourite = true;
      }

      const { connectedOrgs } = userData;

      const firebaseOrgs = connectedOrgs.reduce((acc: FirebaseUserRescueOrg, org) => {
        acc[org.id]= { name: org.name, role: org.role ? org.role : null, favourite: org.favourite };

        return acc;
      }, {});

      const updatedUserData = {
        ...userData,
        connectedOrgs: firebaseOrgs
      }
      await updateDoc(userDocRef, {
        ...updatedUserData
      });
      resolve(`${orgToUpdate?.name}`);
    }));
  }

  reAuth() {
    onAuthStateChanged(this.auth, (user) => {
      if (user) {
        this.updateAuthUser(user.uid);
      } else {
        this.router.navigate(['azonositas']);
      }
    });
  }

  logout() {
    signOut(this.auth)
      .then(() => {
        this.userStore.reset();
        this.router.navigate(['azonositas']);
      })
  }

  canCreate() {
    const allowed = ['admin'];
    return this.checkGlobalRole(GLOBAL_ROLES) || this.checkRescueOrgRole(allowed);
  }
  canRead() {
    const allowed = ['rescuer', 'admin'];
    return this.checkGlobalRole(GLOBAL_ROLES) || this.checkRescueOrgRole(allowed);
  }
  canUpdate() {
    const allowed = ['admin'];
    return this.checkGlobalRole(GLOBAL_ROLES) || this.checkRescueOrgRole(allowed);
  }
  canDelete() {
    const allowed = ['admin'];
    return this.checkGlobalRole(GLOBAL_ROLES) || this.checkRescueOrgRole(allowed);
  }

  isSuperAdmin() {
    return this.checkGlobalRole(GLOBAL_ROLES);
  }

  canAddUser() {
    const allowed = ['admin'];
    return this.checkGlobalRole(GLOBAL_ROLES) || this.checkRescueOrgRole(allowed);
  }

  private checkGlobalRole(allowedRoles: string[]) {
    if (!this.userQuery.getValue()) return false;
    for(const role of allowedRoles) {
      if (this.userQuery.getValue().globalRoles[role as keyof GlobalUserRoles]) {
        return true;
      }
    }
    return false;
  }

  private checkRescueOrgRole(allowedRoles: string[]) {
    if (!this.userQuery.getValue()) return false;
    const currentRescueOrg: RescueOrgInUser | undefined = this.userQuery.connectedOrgs.find((org) => org.id === this.userQuery.selectedRescueOrgId);
    if (!currentRescueOrg) return false;

    for(const role of allowedRoles) {
      if (currentRescueOrg.role === role) {
        return true;
      }
    }
    return false;
  }
}
