import { inject, Injectable } from '@angular/core';
import { BrowserStore }          from './browser.store';
import { NavigationEnd, Router } from '@angular/router';

export enum LocalStorage {
  lastVisitedPage = 'cpm-last-visited-page',
}

@Injectable({ providedIn: 'root' })
export class BrowserService {
  private browserStore = inject(BrowserStore);
  private router = inject(Router);
  htmlTag = document.documentElement;

  restrictedPagesToSave = ['/azonositas', '/szerkesztes'];
  listenForRouterEvent() {
    return this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const contentElement = document.getElementById('cpm-content');
        if (contentElement) {
          contentElement.scrollTop = 0;
        }
        if (!this.restrictedPagesToSave.some((pages) => event.urlAfterRedirects.includes(pages))) {
          window.localStorage.setItem(LocalStorage.lastVisitedPage, event.urlAfterRedirects);
        }
        this.browserStore.update({ currentPath:  event.urlAfterRedirects })
      }
    });
  }

  listenForThemePreferences() {
    this.htmlTag.classList.add('light');
    //this.htmlTag.classList.add(window.matchMedia("(prefers-color-scheme: dark)").matches ? 'dark' : 'light');
  }

  updateTheme(newTheme: string) {
    this.browserStore.update({ theme: newTheme })
    if (newTheme === 'dark') {
      this.htmlTag.classList.replace('light', 'dark');
    } else {
      this.htmlTag.classList.replace('dark', 'light');
    }
  }
}
